var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isAuthenticated ? _c('ul', {
    staticClass: "navigation-menu",
    class: {
      'nav-light': _vm.navLight === true
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.homepage")) + " ")])], 1), _c('li', {
    staticClass: "has-submenu"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'organization.info',
        params: {
          organization_id: _vm.userAccess.organization_id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("organization")) + " ")])], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }