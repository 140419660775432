<script>
import { LOGOUT } from "@/services/store/auth.module";
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";

export default {
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "userAccess"]),
    environment() {
      return process.env.VUE_APP_ENVI;
    },
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    preference: {
      type: Object,
    },
  },
  methods: {
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
    redirectToApi(route) {
      ApiService.post(`redirect/authorize`, {
        user_id: this.currentUser.id,
        route: route,
      })
        .then((response) => {
          // console.log("Redirect", response);
          var url = response.data.url;
          window.location = url;
        })
        .catch((error) => {
          // console.log(error.response);
        });
    },
    logout() {
      const vm = this;
      vm.$store.dispatch(LOGOUT).then(() => {
        if (this.$route.name != "Home") {
          vm.$router.push({ name: "Home" });
        }
      });
      vm.$notify({
        group: "foo",
        title: "Anda telah log keluar dari sistem AWARIS",
        // text: data.message,
        type: "success",
      });
    },
  },
};
</script>

<template>
  <div>
    <ul
      v-if="isAuthenticated"
      class="navigation-menu"
      :class="{ 'nav-light': navLight === true }"
    >
      <li>
        <router-link :to="{ name: 'dashboard' }">
          {{ $t("navbar.homepage") }}
        </router-link>
      </li>
      <li class="has-submenu" v-if="preference.allow_wasiat == 1">
        <a href="javascript:void(0)" @click="onMenuClick">
          {{ $t("navbar.testament") }}</a
        >
        <span class="menu-arrow"></span>
        <ul class="submenu megamenu">
          <li>
            <ul>
              <li>
                <router-link
                  :to="{ name: 'wasiat.list' }"
                  class="side-nav-link-ref"
                  >{{ $t("will-list") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'carian.semak' }"
                  class="side-nav-link-ref"
                  >{{ $t("search2") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'wasiat.part_a' }"
                  class="side-nav-link-ref"
                  >{{ $t("reg-new") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'pindaan.list' }"
                  class="side-nav-link-ref"
                  >{{ $t("amendment.list") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <router-link
                  :to="{ name: 'wasiat.pengesahan.pendaftaran' }"
                  class="side-nav-link-ref"
                  >{{ $t("wasiatasas.confirmation") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'wasiat.pengesahan.list-pindaan' }"
                  class="side-nav-link-ref"
                  >{{ $t("amendment.confirmation") }}</router-link
                >
              </li>

              <li
                v-if="
                  ['local', 'development', 'staging', 'production'].includes(
                    environment
                  )
                "
              >
                <router-link
                  :to="{ name: 'dashboard.report' }"
                  class="side-nav-link-ref"
                  >{{ $t("report") }}</router-link
                >
              </li>
            </ul>
          </li>
          <hr />
          <h6 style="padding-left: 20px">Tekun Nasional</h6>
          <ul style="list-style: none; padding-left: 0; margin: 0;">
            <li>
              <router-link
                :to="{ name: 'tekun.wasiat.list' }"
                class="side-nav-link-ref"
                >{{ $t("tekun.will-list") }}</router-link
              >
            </li>
            <!-- <li>
              <router-link
                :to="{ name: 'tekun.upload.list' }"
                class="side-nav-link-ref"
                >Pengesahan Sijil Wasiat Tekun</router-link
              >
            </li> -->
            <li>
              <router-link
                :to="{ name: 'tekun.report.monthly' }"
                class="side-nav-link-ref"
                >{{ $t("report") }}</router-link
              >
            </li>
          </ul>
        </ul>
      </li>
      <li
        class="has-submenu"
        v-if="
          ['local', 'development', 'staging', 'production'].includes(
            environment
          ) && preference.allow_hibah == 1
        "
      >
        <a href="javascript:void(0)" @click="onMenuClick"> Hibah </a>
        <span class="menu-arrow"></span>
        <ul class="submenu megamenu">
          <li>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="redirectToApi('hibah.products')"
                >
                  {{ $t("reg-new") }}
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="redirectToApi('hibah.list')"
                >
                  {{ $t("trust.hibah-lists") }}
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="redirectToApi('hibah.pending.approval')"
                >
                  {{ $t("trust.confirmation") }}
                </a>
              </li>
              <li class="divider"></li>
            </ul>
          </li>
        </ul>
      </li>
      <li
        class="has-submenu"
        v-if="
          ['local', 'development', 'staging', 'production'].includes(
            environment
          ) && preference.allow_hibah == 1
        "
      >
        <a href="javascript:void(0)" @click="onMenuClick"> Amanah </a>
        <span class="menu-arrow"></span>
        <ul class="submenu megamenu">
          <li>
            <ul>
              <li>
                <router-link
                  :to="{ name: 'trust.product-list' }"
                  class="side-nav-link-ref"
                  >{{ $t("reg-new") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'trust.list',
                  }"
                  class="side-nav-link-ref"
                  >{{ $t("trust.lists") }}</router-link
                >
              </li>
            </ul>
            <hr />
            <h6 style="padding-left: 20px">EzQurban</h6>
            <ul>
              <li>
                <router-link
                  :to="{ name: 'ezqurban.transaction.list' }"
                  class="side-nav-link-ref"
                  >{{ $t("transaction") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'ezqurban.fund-transfer.list' }"
                  class="side-nav-link-ref"
                  >{{ $t("fund-transfer") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'ezqurban.report.monthly' }"
                  class="side-nav-link-ref"
                  >{{ $t("report") }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="has-submenu">
        <a href="javascript:void(0)" @click="onMenuClick">
          {{ $t("navbar.management") }}</a
        >
        <span class="menu-arrow"></span>
        <ul class="submenu megamenu">
          <li>
            <ul>
              <li>
                <router-link
                  :to="{
                    name: 'organization.info',
                    params: { organization_id: userAccess.organization_id },
                  }"
                  class="side-nav-link-ref"
                  >AWARIS</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'organization-rakan.list',
                  }"
                  class="side-nav-link-ref"
                  >{{ $t("business-partner") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <router-link
                  :to="{ name: 'client.list' }"
                  class="side-nav-link-ref"
                  >{{ $t("client") }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
