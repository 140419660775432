var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isAuthenticated ? _c('ul', {
    staticClass: "navigation-menu",
    class: {
      'nav-light': _vm.navLight === true
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.homepage")))])], 1), _vm.preference.allow_wasiat == 1 ? _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.testament")))]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu megamenu"
  }, [_c('li', [_c('ul', [_vm.userRole != 'Client' ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'carian.semak'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("search2")))])], 1) : _vm._e(), _c('li', [_vm.wasiat != null ? _c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'wasiat.part_a',
        query: {
          wasiat_id: this.wasiat_id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.my-will")))]) : _vm._e(), _vm.wasiat_id == null ? _c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'wasiat.part_a'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("reg-new")))]) : _vm._e()], 1), this.wasiat_status == 2 ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'pindaan'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendments2")))])], 1) : _vm._e()])])])]) : _vm._e(), ['local', 'development', 'staging'].includes(_vm.environment) && _vm.hasAnyHibah ? _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.trust")))]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu"
  }, [['Staff', 'Super Admin', 'Admin', 'Agent'].includes(_vm.userRole) ? _c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.redirectToApi('hibah.products');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reg-new")) + " ")])]) : _vm._e(), _c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.redirectToApi('hibah.list.client');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.my-grant")) + " ")])])])]) : _vm._e()]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }