var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isAuthenticated ? _c('ul', {
    staticClass: "navigation-menu",
    class: {
      'nav-light': _vm.navLight === true
    }
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.homepage")) + " ")])], 1), _vm.preference.allow_wasiat == 1 ? _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.testament")))]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu megamenu"
  }, [_c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'wasiat.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("will-list")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'carian.semak'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("search2")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'wasiat.part_a'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("reg-new")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'pindaan.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.list")))])], 1)])]), _c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'wasiat.pengesahan.pendaftaran'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.confirmation")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'wasiat.pengesahan.list-pindaan'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.confirmation")))])], 1), ['local', 'development', 'staging', 'production'].includes(_vm.environment) ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'dashboard.report'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("report")))])], 1) : _vm._e()])]), _c('hr'), _c('h6', {
    staticStyle: {
      "padding-left": "20px"
    }
  }, [_vm._v("Tekun Nasional")]), _c('ul', {
    staticStyle: {
      "list-style": "none",
      "padding-left": "0",
      "margin": "0"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'tekun.wasiat.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("tekun.will-list")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'tekun.report.monthly'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("report")))])], 1)])])]) : _vm._e(), ['local', 'development', 'staging', 'production'].includes(_vm.environment) && _vm.preference.allow_hibah == 1 ? _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(" Hibah ")]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu megamenu"
  }, [_c('li', [_c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.redirectToApi('hibah.products');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reg-new")) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.redirectToApi('hibah.list');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.hibah-lists")) + " ")])]), _c('li', [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.redirectToApi('hibah.pending.approval');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.confirmation")) + " ")])]), _c('li', {
    staticClass: "divider"
  })])])])]) : _vm._e(), ['local', 'development', 'staging', 'production'].includes(_vm.environment) && _vm.preference.allow_hibah == 1 ? _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(" Amanah ")]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu megamenu"
  }, [_c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'trust.product-list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("reg-new")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'trust.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("trust.lists")))])], 1)]), _c('hr'), _c('h6', {
    staticStyle: {
      "padding-left": "20px"
    }
  }, [_vm._v("EzQurban")]), _c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'ezqurban.transaction.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("transaction")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'ezqurban.fund-transfer.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("fund-transfer")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'ezqurban.report.monthly'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("report")))])], 1)])])])]) : _vm._e(), _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.management")))]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu megamenu"
  }, [_c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'organization.info',
        params: {
          organization_id: _vm.userAccess.organization_id
        }
      }
    }
  }, [_vm._v("AWARIS")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'organization-rakan.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("business-partner")))])], 1)])]), _c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'client.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("client")))])], 1)])])])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }