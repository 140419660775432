var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "defaultscroll sticky",
    class: {
      'bg-white': _vm.isWhiteNavbar === true
    },
    attrs: {
      "id": "topnav"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "text-white text-right pr-4 btn-hai",
    attrs: {
      "id": "div_language"
    }
  }, [_c('p', {
    staticClass: "p-lang"
  }, [_vm._v(" LANGUAGE "), _c('span', {
    staticClass: "lang-bg ml-2 rounded"
  }, [_c('span', {
    class: _vm.$i18n.locale == 'ms' ? 'text-white' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('ms');
      }
    }
  }, [_vm._v(" BM ")]), _vm._v(" | "), _c('span', {
    class: _vm.$i18n.locale == 'en' ? 'text-white' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('en');
      }
    }
  }, [_vm._v(" ENG ")])])])]), _c('div', [_vm.navLight !== true ? _c('router-link', {
    staticClass: "logo mb-0 p-0",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/logo-awaris.png",
      "height": "40",
      "alt": ""
    }
  })]) : _c('router-link', {
    staticClass: "logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "l-dark",
    attrs: {
      "src": "/images/logo-awaris.png",
      "height": "60",
      "alt": ""
    }
  }), _c('img', {
    staticClass: "l-light",
    attrs: {
      "src": "/images/logo-awaris-white.png",
      "height": "60",
      "alt": ""
    }
  })])], 1), !_vm.isAuthenticated ? _c('div', {
    staticClass: "buy-button"
  }, [_c('router-link', {
    staticClass: "btn",
    class: {
      'btn-light': _vm.navLight === true,
      'btn-primary': _vm.navLight !== true
    },
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("log-in")))])], 1) : _vm._e(), _vm.isAuthenticated ? _c('div', {
    staticClass: "buy-button btn-hai",
    staticStyle: {
      "line-height": "100%"
    }
  }, [_c('ul', {
    staticClass: "navigation-menu"
  }, [_c('li', {
    staticClass: "has-submenu last-elements"
  }, [_vm._m(0), _c('ul', {
    staticClass: "submenu"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'profile'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.profile")) + " ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": {
        name: 'change-password'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.change-password")) + " ")])], 1), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.logout")) + " ")])])])])])]) : _vm._e(), _c('div', {
    staticClass: "menu-extras"
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('a', {
    staticClass: "navbar-toggle",
    class: {
      open: _vm.isCondensed === true
    },
    on: {
      "click": function ($event) {
        return _vm.toggleMenu();
      }
    }
  }, [_vm._m(1)])])]), _vm.isAuthenticated ? _c('div', {
    attrs: {
      "id": "navigation"
    }
  }, [_vm.userRole == 'Staff' ? _c('StaffNavigation', {
    attrs: {
      "nav-light": _vm.navLight,
      "preference": _vm.preference
    }
  }) : _vm._e(), _vm.userRole == 'Admin' ? _c('AdminNavigation', {
    attrs: {
      "nav-light": _vm.navLight,
      "preference": _vm.preference
    }
  }) : _vm._e(), _vm.userRole == 'Agent' ? _c('AgentNavigation', {
    attrs: {
      "nav-light": _vm.navLight,
      "preference": _vm.preference
    }
  }) : _vm._e(), _vm.userRole == 'Super Admin' ? _c('OrganizationSuperAdminNavigation', {
    attrs: {
      "nav-light": _vm.navLight,
      "preference": _vm.preference
    }
  }) : _vm._e(), _vm.userRole == 'Client' ? _c('UserNavigation', {
    attrs: {
      "nav-light": _vm.navLight,
      "preference": _vm.preference
    }
  }) : _vm._e(), _vm.userRole == 'IT' ? _c('ItNavigation', {
    attrs: {
      "nav-light": _vm.navLight,
      "preference": _vm.preference
    }
  }) : _vm._e(), _c('hr', {
    staticClass: "show-max"
  }), _c('ul', {
    staticClass: "navigation-menu nav-user",
    class: {
      'nav-light': _vm.navLight === true
    }
  }, [_c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(_vm._s(_vm.$t("settings")))]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu megamenu"
  }, [_c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'profile'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.profile")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'change-password'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.change-password")))])], 1), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.logout")) + " ")])])])])])])])], 1) : _vm._e()])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticStyle: {
      "padding-top": "15px"
    },
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "l-dark",
    attrs: {
      "src": "/images/icon/settings.png",
      "height": "30",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lines"
  }, [_c('span'), _c('span'), _c('span')]);
}]

export { render, staticRenderFns }