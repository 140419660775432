<script>
import { LOGOUT } from "@/services/store/auth.module";
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";

export default {
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "userAccess"]),
    environment() {
      return process.env.VUE_APP_ENVI;
    },
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    preference: {
      type: Object,
    },
  },
  methods: {
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
    redirectToApi(route) {
      ApiService.post(`redirect/authorize`, {
        user_id: this.currentUser.id,
        route: route,
      })
        .then((response) => {
          console.log("Redirect", response);
          var url = response.data.url;
          window.location = url;
        })
        .catch((error) => {
          // console.log(error.response);
        });
    },
    logout() {
      const vm = this;
      vm.$store.dispatch(LOGOUT).then(() => {
        if (this.$route.name != "Home") {
          vm.$router.push({ name: "Home" });
        }
      });
      vm.$notify({
        group: "foo",
        title: "Anda telah log keluar dari sistem AWARIS",
        // text: data.message,
        type: "success",
      });
    },
  },
};
</script>

<template>
  <div>
    <ul
      v-if="isAuthenticated"
      class="navigation-menu"
      :class="{ 'nav-light': navLight === true }"
    >
      <li>
        <router-link :to="{ name: 'dashboard' }" class="side-nav-link-ref">{{
          $t("navbar.homepage")
        }}</router-link>
      </li>
      <li class="has-submenu" v-if="preference.allow_wasiat == 1">
        <a href="javascript:void(0)" @click="onMenuClick">{{
          $t("navbar.testament")
        }}</a>
        <span class="menu-arrow"></span>
        <ul class="submenu megamenu">
          <li>
            <ul>
              <li v-if="this.userAccess.organization.code == 'TN'">
                <router-link
                  :to="{ name: 'tekun.wasiat.list' }"
                  class="side-nav-link-ref"
                  >{{ $t("will-list") }}</router-link
                >
              </li>
              <li v-else>
                <router-link
                  :to="{ name: 'wasiat.list' }"
                  class="side-nav-link-ref"
                  >{{ $t("will-list") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'carian.semak' }"
                  class="side-nav-link-ref"
                  >{{ $t("search2") }}</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'wasiat.part_a' }"
                  class="side-nav-link-ref"
                  >{{ $t("reg-new") }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li
        class="has-submenu"
        v-if="
          ['local', 'development', 'staging'].includes(environment) &&
          preference.allow_hibah == 1
        "
      >
        <a href="javascript:void(0)" @click="onMenuClick">Hibah</a>
        <span class="menu-arrow"></span>
        <ul class="submenu">
          <li>
            <a
              href="javascript:void(0)"
              @click="redirectToApi('hibah.products')"
            >
              {{ $t("reg-new") }}
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="redirectToApi('hibah.list')">
              {{ $t("trust.hibah-lists") }}
            </a>
          </li>
        </ul>
      </li>
      <li
        class="has-submenu"
        v-if="
          ['local', 'development', 'staging'].includes(environment) &&
          preference.allow_hibah == 1
        "
      >
        <a href="javascript:void(0)" @click="onMenuClick">Amanah</a>
        <span class="menu-arrow"></span>
        <ul class="submenu">
          <li>
            <router-link
              :to="{ name: 'trust.product-list' }"
              class="side-nav-link-ref"
              >{{ $t("reg-new") }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'trust.list' }"
              class="side-nav-link-ref"
              >{{ $t("trust.lists") }}</router-link
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
