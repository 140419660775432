var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isAuthenticated ? _c('ul', {
    staticClass: "navigation-menu",
    class: {
      'nav-light': _vm.navLight === true
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.homepage")))])], 1), _vm.preference.allow_wasiat == 1 ? _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.testament")))]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu megamenu"
  }, [_c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'template'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.homepage")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'template'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("will-list")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'carian.semak'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("search2")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'wasiat.part_a'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("reg-new")))])], 1)])]), _c('li', [_c('ul', [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'template'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("verification")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'template'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendments2")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'template'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("report")))])], 1)])])])]) : _vm._e(), ['local', 'development', 'staging'].includes(_vm.environment) && _vm.preference.allow_hibah == 1 ? _c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": _vm.onMenuClick
    }
  }, [_vm._v("Amanah")]), _c('span', {
    staticClass: "menu-arrow"
  }), _c('ul', {
    staticClass: "submenu"
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'trust.product-list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("reg-new")))])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link-ref",
    attrs: {
      "to": {
        name: 'trust.list'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("trust.lists")))])], 1)])]) : _vm._e()]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }